export const environment = {
  production: true,
  apiUrl: "/api",
  frontOfficeUrl: {
    internal: "https://prismqa.int.us.randstad.com/psp/FOQA/EMPLOYEE/RFO/c",
  },
  bullhornUrl: "https://bullhornstaffing.com/BullhornStaffing/OpenWindow.cfm",
  env: "qa",
  auth0: {
    domain: "globalidpdta.randstadservices.com",
    clientId: "tHIWxBHIdTwgOknvqFJSy8RBeX2z3YyU",
    audience: "https://XploreTest/",
    server: "https://qa-xplore.randstadusa.com/",
  },
  collections: {
    IDManager: "dev_JobIDManager",
    suggestedQuestions: "dev_recruiterSuggestedQuestions",
    CompanyIDManager: "dev_CompanyIDManager",
    ApplicationIDManager: "dev_ApplicationIDManager",
    Conversations: "dev_Conversations",
    SelectedPrompts: "dev_recruiterSelectedPrompts",
    PlatformAlerts: "dev_UI_Banners",
    Bookmark: "dev_Bookmark",
    VMSJob: "dev_VmsJobs",
    ApplicationChangeManager: "dev_ApplicationChangeManager",
  },
  firebase: {
    apiKey: "AIzaSyCY8Zu0HsGQqlB_L5P1HaiTGiS-w0L2UCw",
    authDomain: "us-allbirdsapi-qa-edac.firebaseapp.com",
    databaseURL: "https://us-allbirdsapi-qa-edac.firebaseio.com",
    projectId: "us-allbirdsapi-qa-edac",
    storageBucket: "",
    messagingSenderId: "927918280928",
    appId: "1:927918280928:web:c04a1d4d9458a0f7",
  },
};
